import React from 'react'
import "./Services.css"
import {BiCheck} from "react-icons/bi"

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        {/* UI/UX */}
        <article className='service'>
        <div className='services__head'>
          <h3>UI/UX Design</h3>
        </div>
        <div className='services__list'>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating Wireframes</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating a Persona</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating Colour Pallette</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Designing Systems</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating Icon Library </p>
          </li>
        </div>
        </article>
        {/* Web Dev */}
        <article className='service'>
        <div className='services__head'>
          <h3>Web Development</h3>
        </div>
        <div className='services__list'>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating a landing page</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating a responsive website or webapp</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Creating basic website features</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Developing and maintaining the user interface</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Optimizing the user experience</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p> Implementing design on mobile websites</p>
          </li>
        </div>
        </article>
        {/* Mobile App Dev */}
        <article className='service'>
        <div className='services__head'>
          <h3>Mobile App Development</h3>
          <small>Coming Soon</small>
        </div>
        <div className='services__list'>
          <li>
            <BiCheck className='service__list__icon' />
            <p>Creating a responsive mobile app</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p>Creating a server</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p>Adding basic mobile app features</p>
          </li>
          <li>
            <BiCheck className='service__list__icon' />
            <p>Deploying your app to Playstore or Applestore</p>
          </li>
        </div>
        </article>
      </div>
    </section>
  )
}

export default Services