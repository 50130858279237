import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FiGithub } from 'react-icons/fi'
import { BsInstagram } from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href='https://Linkedin.com/in/samuel-onwodi-474637b0/' target="_blank" rel="noreferrer"> <BsLinkedin /></a>
        <a href='https://GitHub.com/Onwodis' target="_blank" rel="noreferrer"> <FiGithub /></a>
        <a href='https://instagram.com/officialsamon' target="_blank" rel="noreferrer"><BsInstagram /></a>
    </div>
  )
}

export default HeaderSocials