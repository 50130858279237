import React from 'react'


import About from "./Components/about/About"
import Contact from './Components/contact/Contact'
import Header from './Components/header/Header'
import Testimonials from './Components/testimonials/Testimonials'
import Experience from './Components/experience/Experience'
import Services from './Components/services/Services'
import Portfolio from './Components/portfolio/Portfolio'
import Footer from './Components/footer/Footer'
import Navbar from './Components/navbar/Navbar'
import Certs from './Components/certs/Certs'


const App = () => {
  return (
 <>
   <Header />
   <Navbar />
   <About />
   <Experience />
   <Services />
   <Portfolio />
   <Testimonials />
   <Certs />
   <Contact />
   <Footer />


 </>
   
  )
}

export default App
