import React from 'react'
import './certs.css';
import Webcert from "../../Assets/certs/web development certificate.PNG"
import CodarAward from "../../Assets/certs/web award.PNG"
import Unibencert from '../../Assets/certs/SAM CERTIFICATE.jpg';
import Solarcert from '../../Assets/certs/SOLAR BOOTCAMP.jpeg';
import Nysc from '../../Assets/certs/NYSC CERTIFICATE.jpeg';
// import Jamb from '../../Assets/certs/Jamb Result.jpg';
// import Waec from '../../Assets/certs/Waec cert.PNG';
// import Primarycert from '../../Assets/certs/PRIMARY SCHOOL CERTIFICATE.jpeg';
// import Primarytestimonial from '../../Assets/certs/PRIMARY SCHOOL TESTIMONIAL.jpeg';
import Road from '../../Assets/certs/FRSC CERT.jpeg';



const Certs = () => {
  return (
    <section id="portfolio">
      <div className="projects_div">
        {/* <h1 className='wordprojects'>Projects</h1> */}
        <h5>My credentials ?</h5>
        <h2>Certificates & Awards</h2>

        <div className="project_itemss">
          <div className="sites_div">
            <div className="cert_div">
              <img src={Webcert} alt="" />
            </div>
            <h2 className="vid_title">Awarded by Codar Institute</h2>

            <h4 className="h4_talk">Full-stack web development certificate</h4>
            <br />
          </div>
          <div className="sites_div">
            <div className="cert_div">
              <img src={CodarAward} alt="" />
            </div>
            {/* <h2 className="vid_title">Awarded by Codar Institute</h2>

            <h4 className="h4_talk">Full-stack web development certificate</h4> */}
            <br />
          </div>
          <div className="sites_div">
            <div className="cert_div">
              <img src={Solarcert} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by Rensource distributed Energy plc
            </h2>

            <h4 className="h4_talk">Solar power bootcamp certificate</h4>
            <br />
          </div>

          <div className="sites_div">
            <div className="cert_div">
              <img src={Nysc} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by National Youth Service Corps.
            </h2>

            <h4 className="h4_talk">National Youth Service Corps</h4>
            <br />
          </div>
          <div className="sites_div">
            <div className="cert_div">
              <img src={Unibencert} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by The University of Benin,benin-city.
            </h2>

            <h4 className="h4_talk">Bachelor of Engineering</h4>
            <br />
          </div>
          {/* <div className="sites_div">
            <div className="cert_div">
              <img src={Jamb} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by The Joint Admission Matriculation Board.
            </h2>

            <h4 className="h4_talk">JAMB Results</h4>
            <br />
          </div> */}
          {/* <div className="sites_div">
            <div className="cert_div">
              <img src={Waec} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by The West African Examinations Council.
            </h2>

            <h4 className="h4_talk">WAEC certificate</h4>
            <br />
          </div> */}
          {/* <div className="sites_div">
            <div className="cert_div">
              <img src={Primarycert} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by Lagos state government ministry of education.
            </h2>

            <h4 className="h4_talk">Primary School leaving certificate</h4>
            <br />
          </div> */}
          {/* <div className="sites_div">
            <div className="cert_div">
              <img src={Primarytestimonial} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by Lagos state government ministry of education.
            </h2>

            <h4 className="h4_talk">Primary School leaving certificate</h4>
            <br />
          </div> */}
          <div className="sites_div">
            <div className="cert_div">
              <img id='frsc' src={Road} alt="" />
            </div>
            <h2 className="vid_title">
              Awarded by Federal Road Safety Corps.
            </h2>

            <h4 className="h4_talk">FRSC</h4>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Certs