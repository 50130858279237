import React from 'react'
import "./Contact.css"
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine } from 'react-icons/ri'
import {BsWhatsapp } from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {

  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o0nq2ag', 'template_jsg5vtt', form.current, 'Lkm4OO0CjgFLgehye')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };


  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact me</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option_icon'/>
            <h4>Email</h4>
            <h5>samuelonwodi@yahoo.com</h5>
            <a href="mailto:samuelonwodi@yahoo.com" target="_blank" rel="noreferrer">Send a message</a>
          </article>
          <article className='contact_option'>
            <RiMessengerLine className='contact_option_icon' />
            <h4>Messenger</h4>
            <h5>Samuel Onwodi</h5>
            <a href="http://m.me/profile.php?id=100070069762030" target="_blank" rel="noreferrer">Send a message</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option_icon' />
            <h4>Whatsapp</h4>
            <h5>+2348130757003</h5>
            <a href="https://wa.me/2348130757003?text=Hi ,I have a job for you." target="_blank" rel="noreferrer">Send a message</a>
          </article>
        </div>

        {/* end of article */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder='Your full name' name='name' required/>
          <input type="email" placeholder='Your email' name='email' required/>
          <textarea name="message" id=""  rows="7" placeholder='Your message' required>

          </textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>

        </form>

      </div>
    </section>
  )
}

export default Contact