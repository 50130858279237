import React from 'react'
import Resume from "../../Assets/Resume.pdf"

const CTA = () => {
  return (
    <div className='cta'>
        <a href={Resume} download className='btn'>Download My CV</a>
        <a href='#contact' id="hire-me" className='btn btn-primary'>Hire Me</a>
    </div>
  )
}
    export default CTA