import React from 'react'
import "./Footer.css"

import { BsInstagram } from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs'
import { FaTwitter } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer id="footer">
      <a href="#" className='footer_logo'>Samuel Onwodi</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>

      </ul>
      <div className="footer_socials">
        <a href="http://facebook.com/profile.php?id=100070069762030" target="_blank" rel="noreferrer"><BsFacebook/></a>
        
        <a href='https://instagram.com/officialsamon' target="_blank" rel="noreferrer"><BsInstagram /></a> 
        <a href='https://twitter.com/officialsamon' target="_blank" rel="noreferrer"><FaTwitter /></a> 
      </div>
      <div className="footer_copyright">
        <small>&copy; Samuel Onwodi.all rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer