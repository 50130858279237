import React from 'react'
import CTA from "./CTA"
import MyPicture from "../../Assets/me.png"
import HeaderSocials from './HeaderSocials'
import "./Header.css"

const Header = ({activeNav, setActiveNav}) => {


  return (
    <header id="header">
      <div className='container header__container'>
        <h5>Hello I'm</h5>
        <h1>Samuel O. Onwodi</h1>
        <h5 className='text-light'>MERN-stack Software Developer [BE-heavy]</h5>
        <CTA />
        <HeaderSocials />
        

        <div className='MyPicture'>
          <img src={MyPicture} alt='this is the author'/>
        </div>

        
        <div className='scroll__down'>
        <a href='#about'>Scroll Down</a>
        </div>
      </div>
    </header>
  )
}

export default Header