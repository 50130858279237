import React from 'react'
// import {Component} from 'react'
import "./Portfolio.css"
import Pinkpepper from '../../Assets/videos/pink.mp4';
import Ludo from "../../Assets/videos/useludo.mp4"
import Onshare from '../../Assets/videos/onsharevid.mp4';
import Codar from '../../Assets/videos/codarhq.mp4';
import Codee from '../../Assets/videos/codee.mp4';
import Cos from '../../Assets/videos/cos.mp4';
// import VideoPlayer from "react-video-js-player"

const Portfolio = () => {


  return (
    <section id="portfolio">
      <div className="projects_div">
        {/* <h1 className='wordprojects'>Projects</h1> */}
        <h5>What have i built ?</h5>
        <h2>Some of my projects</h2>

        <div className="project_items">
          <div className="site_div">
            {/* <video src={Ediblee} className="main_video"></video>
            <VideoPlayer 
            src={videosrc}
            width= "720" 
            height = "420"     
             /> */}
            <a
              href="https://pinkpepper.onrender.com/"
              target="_blank"
              rel="noreferrer"
            >
              <video
                className="video"
                src={Pinkpepper}
                autoPlay
                muted
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">https://pinkpepper.onrender.com/</h2>

            <h4 className="h4_talk">
              A fully fledged e-commerce website with complex system robotic
              design.
              {/* <span className="cms"> CMS</span>. */}
            </h4>
            <br />
            <ol>
              <li className="lists">HTML,CSS,BOOTSTRAP</li>
              <li className="lists">JAVASCRIPT,JQUERY</li>
              <li className="lists">NODEJS,MONGODB</li>
            </ol>
          </div>
          <div className="site_div">
            <a href="https://codarhq.com/" target="_blank" rel="noreferrer">
              <video
                className="video"
                src={Codar}
                autoPlay
                muted
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">https://codarhq.com/</h2>

            <h4 className="h4_talk">
              A school website with business system robot integrated.
            </h4>
            <br />
            <ol>
              <li className="lists">HTML,CSS,BOOTSTRAP</li>
              <li className="lists">JAVASCRIPT,JQUERY</li>
              <li className="lists">NODEJS,MONGODB</li>
            </ol>
          </div>
          <div className="site_div">
            <a
              href="https://cosiso.onrender.com/men-banner"
              target="_blank"
              rel="noreferrer"
            >
              <video
                className="video"
                src={Cos}
                autoPlay
                muted
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">
              https://cosiso.onrender.com/men-banner
            </h2>

            <h4 className="h4_talk">A fashion e-commerce website.</h4>
            <br />
            <ol>
              <li className="lists">HTML,CSS,BOOTSTRAP</li>
              <li className="lists">JAVASCRIPT,JQUERY</li>
              <li className="lists">NODEJS,MONGODB</li>
            </ol>
          </div>
          <div className="site_div">
            <a
              href="https://justcodee.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              <video
                className="video"
                src={Codee}
                autoPlay
                muted
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">https://justcodee.netlify.app/</h2>

            <h4 className="h4_talk">An online school website.</h4>
            <br />
            <ol>
              <li className="lists">React</li>
            </ol>
          </div>

          <div className="site_div">
            <a
              href="https://onshare-0z8i.onrender.com/"
              target="_blank"
              rel="noreferrer"
            >
              <video
                className="video"
                src={Onshare}
                autoplay
                muted
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">onshare-0z8i.onrender.com</h2>

            <h4 className="h4_talk">
              A file sharing web api with credence given to security, privacy
              with customer management system . .
            </h4>
            <br />
            <ol>
              <li className="lists">HTML,CSS,BOOTSTRAP</li>
              <li className="lists">JAVASCRIPT,JQUERY</li>
              <li className="lists">NODEJS,MONGODB</li>
            </ol>
          </div>
          <div className="site_div">
            <a
              href="https://codarludo-t9mn.onrender.com/"
              target="_blank"
              rel="noreferrer"
            >
              <video
                className="video"
                src={Ludo}
                autoplay
                loop
                controls
              ></video>
            </a>
            <h2 className="vid_title">codarludo-t9mn.onrender.com</h2>

            <h4 className="h4_talk">
              A web api game "LUDO " with full e-commerce customer management
              system .
              <br /> You can play with your friends !.
            </h4>
            <br />
            <ol>
              <li className="lists">HTML,CSS,BOOTSTRAP</li>
              <li className="lists">JAVASCRIPT,JQUERY</li>
              <li className="lists">NODEJS,MONGODB</li>
            </ol>
          </div>
          {/* <div className="site_div">o</div> */}
        </div>
      </div>
    </section>
  );
}

export default Portfolio