import React from 'react'
import "./testimonials.css"
import Avatar1 from "../../Assets/david.png"
import Avatar2 from "../../Assets/success.png"
import Avatar3 from "../../Assets/emmanuel.png"
import Avatar4 from "../../Assets/sydney.png"



import { Swiper, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
import 'swiper/css'; // core Swiper
import {Pagination,Navigation} from 'swiper'; // core Swiper


const Testimonials = () => {

  const data = [
    {
      name: "David Sokefun",
      review: "Samuel is a passionate and intelligent programmer.",
      avatar: Avatar1,
    },
    {
    name: "Success Opara",
      review: "He takes his job serious and keeps to project deadlines.",
        avatar: Avatar2,
    },
  {
    name: "Emmanuel Asaolu ",
      review: "Samuel is an excellent and outstanding scholar with an irrefutable learning prowess.",
      avatar: Avatar3,
    },
  {
    name: "Sydney Nwakanma ",
      review: "Samuel Onwodi is a uniquely talented Individual.",
      avatar: Avatar4,
  },

]




return (
  < section id="testimonials" >
    <h5>Review from clients</h5>
    <h2>Testimonials</h2>

    <Swiper
    modules={[Pagination,Navigation]}
    spaceBetween={40}
    slidesPerView={1}
    pagination={{clickable: true}}
     className="container testimonials__container">
      {data.map(({ name, review, avatar }, index) => {
        return (
          <SwiperSlide key={index} className='testimonial'>
            <div className="client_avatar">
              <img src={avatar} alt='Avatar'/>

            </div>
            <h5 className="client--name">{name}</h5>
            <small className='client__review'>
              {review}
            </small>
          </SwiperSlide>
        )
      })}

      


    </Swiper>
    
  </section >
)
}

export default Testimonials